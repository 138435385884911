/**
 * DO NOT MANUALLY EDIT THIS FILE
 * This file is generated automatically by the build script on prebuild step.
 * Please refer to documentation for more information
 */

export default function Settings({ children }) {
  return children({
    mainNavigation: [
      {
        label: 'Peinture',
        href: '/fr/art/peintures',
        columns: [
          {
            items: [
              {
                label: 'Peintures prestigieuses',
                href: '/fr/art/peintures??scene=confirme',
                obfuscate: true,
                icon: 'established',
                bold: true,
                className: '',
              },
              {
                label: 'Nouveautés',
                href: '/fr/art/peinturest?scene=nouveau',
                obfuscate: true,
                icon: 'new',
                bold: true,
                className: '',
              },
              {
                label: 'Art émergent',
                href: '/fr/art/peinturest?scene=emergent',
                obfuscate: true,
                icon: 'emerging',
                bold: true,
                className: '',
              },
              {
                label: 'Toutes les peintures',
                href: '/fr/art/peintures',
                bold: true,
                className: 'ra-hide-for-large-only ra-hide-for-xlarge',
              },
              { type: 'separator', className: '' },
              {
                label: 'Peintures contemporaines',
                href: '/fr/art/peintures',
                obfuscate: true,
                bold: true,
                className: 'ra-show-for-large ra-hide-for-small-only ra-hide-for-medium-only',
              },
            ],
            className: '',
          },
          {
            items: [
              { label: 'Peintures abstraites', href: '/fr/art/peintures/abstrait', className: '' },
              {
                label: 'Peintures figuratives',
                href: '/fr/art/peintures/figuratif',
                className: '',
              },
              { label: "Peintures à l'huile", href: '/fr/art/peintures/huile', className: '' },
              { label: 'Peintures acryliques', href: '/fr/art/peintures/acrylique', className: '' },
              { label: 'Peintures de paysages', href: '/fr/art/peintures/paysage', className: '' },
              { label: 'Peintures Art déco', href: '/fr/art/peintures/art-deco', className: '' },
              { label: 'Peintures de nus', href: '/fr/art/peintures/nu', className: '' },
              { label: 'Peintures Pop Art', href: '/fr/art/peintures/pop-art', className: '' },
            ],
            className: '',
          },
          {
            items: [
              {
                type: 'box',
                label: 'Nos plus belles peintures',
                text: "Des créations originales d'artistes en pleine ascension",
                href: '/fr/collection/1693/nos-plus-belles-peintures ',
                image:
                  'https://d1ee3oaj5b5ueh.cloudfront.net/original/file/2023/12/f0afe266-a5c7-402e-8300-275f5a5ed859.jpg',
                className: '',
              },
            ],
            className: '',
          },
        ],
        className: '',
      },
      {
        label: 'Édition',
        href: '/fr/art/edition',
        columns: [
          {
            items: [
              {
                label: 'Éditions prestigieuses',
                href: '/fr/art/editiont?scene=confirme',
                obfuscate: true,
                icon: 'established',
                bold: true,
                className: '',
              },
              {
                label: 'Nouveautés',
                href: '/fr/art/editions?scene=nouveau',
                obfuscate: true,
                icon: 'new',
                bold: true,
                className: '',
              },
              {
                label: 'Art émergent',
                href: '/fr/art/edition?scene=emergent',
                obfuscate: true,
                icon: 'emerging',
                bold: true,
                className: '',
              },
              {
                label: 'Toutes les éditions',
                href: '/fr/art/edition',
                bold: true,
                className: 'ra-hide-for-large-only ra-hide-for-xlarge',
              },
              { type: 'separator', className: '' },
              {
                label: 'Editions Fine Art',
                href: '/fr/art/edition',
                obfuscate: true,
                bold: true,
                className: 'ra-show-for-large ra-hide-for-small-only ra-hide-for-medium-only',
              },
            ],
            className: '',
          },
          {
            items: [
              { label: 'Editions Street Art', href: '/fr/art/edition/street-art', className: '' },
              { label: 'Editions Pop Art', href: '/fr/art/edition/pop-art', className: '' },
              { label: 'Editions paysages', href: '/fr/art/edition/paysage', className: '' },
              { label: 'Editions botaniques', href: '/fr/art/edition/botanique', className: '' },
              { label: 'Editions animaux', href: '/fr/art/edition/animaux', className: '' },
              { label: 'Sérigraphies', href: '/fr/art/edition/serigraphie', className: '' },
              { label: 'Impressions sur lino', href: '/fr/art/edition/lino', className: '' },
              { label: 'Impressions monochromes', href: '/fr/art/edition/mono', className: '' },
            ],
            className: '',
          },
          {
            items: [
              {
                type: 'box',
                label: 'Nos plus belle éditions',
                text: 'Des tirages de qualité supérieure pour les collectionneurs exigeants',
                href: '/fr/collection/1697/nos-plus-belles-editions',
                image:
                  'https://d1ee3oaj5b5ueh.cloudfront.net/original/file/2023/12/44a16986-1919-4b4c-8fdc-8d23575ee89a.jpg',
                className: '',
              },
            ],
            className: '',
          },
        ],
        className: '',
      },
      {
        label: 'Photographie',
        href: '/fr/art/photographie',
        columns: [
          {
            items: [
              {
                label: 'Photographies prestigieuses',
                href: '/fr/art/photographie?scene=confirme',
                obfuscate: true,
                icon: 'established',
                bold: true,
                className: '',
              },
              {
                label: 'Nouveautés',
                href: '/fr/art/photographie?scene=nouveau',
                obfuscate: true,
                icon: 'new',
                bold: true,
                className: '',
              },
              {
                label: 'Art émergent',
                href: '/fr/art/photographie?scene=emergent',
                obfuscate: true,
                icon: 'emerging',
                bold: true,
                className: '',
              },
              {
                label: 'Tous les photographes',
                href: '/fr/art/photographie',
                bold: true,
                className: 'ra-hide-for-large-only ra-hide-for-xlarge',
              },
              { type: 'separator', className: '' },
              {
                label: "Photographies d'art contemporain",
                href: '/fr/art/photographie',
                obfuscate: true,
                bold: true,
                className: 'ra-show-for-large ra-hide-for-small-only ra-hide-for-medium-only',
              },
            ],
            className: '',
          },
          {
            items: [
              {
                label: 'Photographie abstraite',
                href: '/fr/art/photographie/abstrait',
                className: '',
              },
              {
                label: 'Photographie de paysage',
                href: '/fr/art/photographie/paysage',
                className: '',
              },
              { label: 'Photographie de nu', href: '/fr/art/photographie/nu', className: '' },
              {
                label: 'Photographie noir et blanc',
                href: '/fr/art/photographie/noir-et-blanc',
                className: '',
              },
              {
                label: 'Photographie numérique',
                href: '/fr/art/photographie/numerique',
                className: '',
              },
            ],
            className: '',
          },
          {
            items: [
              {
                type: 'box',
                label: 'Nos plus belle photographies',
                text: 'Vivez à travers l’objectif de nos photographes',
                href: '/fr/collection/1698/nos-plus-belles-photographies',
                image:
                  'https://d1ee3oaj5b5ueh.cloudfront.net/original/file/2023/12/161f82fe-0193-487f-9d40-e6d472766137.jpg',
                className: '',
              },
            ],
            className: '',
          },
        ],
        className: '',
      },
      {
        label: 'Sculpture',
        href: '/fr/art/sculpture',
        columns: [
          {
            items: [
              {
                label: 'Sculptures prestigieuses',
                href: '/fr/art/sculpture?scene=confirme',
                obfuscate: true,
                icon: 'established',
                bold: true,
                className: '',
              },
              {
                label: 'Nouveautés',
                href: '/fr/art/sculpture?scene=nouveau',
                obfuscate: true,
                icon: 'new',
                bold: true,
                className: '',
              },
              {
                label: 'Art émergent',
                href: '/fr/art/sculpturet?scene=emergent',
                obfuscate: true,
                icon: 'emerging',
                bold: true,
                className: '',
              },
              {
                label: 'Toutes les sculptures',
                href: '/fr/art/sculpture',
                bold: true,
                className: 'ra-hide-for-large-only ra-hide-for-xlarge',
              },
              { type: 'separator', className: '' },
              {
                label: 'Sculptures contemporaines',
                href: '/fr/art/sculpture',
                obfuscate: true,
                bold: true,
                className: 'ra-show-for-large ra-hide-for-small-only ra-hide-for-medium-only',
              },
            ],
            className: '',
          },
          {
            items: [
              { label: 'Sculpture abstraite', href: '/fr/art/sculpture/abstrait', className: '' },
              { label: 'Sculpture figurative', href: '/fr/art/sculpture/figuratif', className: '' },
              { label: 'Sculpture de nu', href: '/fr/art/sculpture/nu', className: '' },
              { label: 'Sculpture en métal', href: '/fr/art/sculpture/metal', className: '' },
              { label: 'Sculpture en bronze', href: '/fr/art/sculpture/bronze', className: '' },
              { label: 'Sculpture en bois', href: '/fr/art/sculpture/bois', className: '' },
              {
                label: 'Sculpture en céramique',
                href: '/fr/art/sculpture/ceramique',
                className: '',
              },
            ],
            className: '',
          },
          {
            items: [
              {
                type: 'box',
                label: 'Nos plus belles sculptures',
                text: 'De l’infiniment petit à la sculpture monumentale',
                href: '/fr/collection/1694/nos-plus-belles-sculptures',
                image:
                  'https://d1ee3oaj5b5ueh.cloudfront.net/original/file/2023/12/e9ec57a9-f5b5-469d-9a21-049309bc0e47.jpg',
                className: '',
              },
            ],
            className: '',
          },
        ],
        className: '',
      },
      {
        label: 'Dessin',
        href: '/fr/art/dessins',
        columns: [
          {
            items: [
              {
                label: 'Dessins prestigieux',
                href: '/fr/art/dessins?scene=confirme',
                obfuscate: true,
                icon: 'established',
                bold: true,
                className: '',
              },
              {
                label: 'Nouveautés',
                href: '/fr/art/dessins?stage=new',
                obfuscate: true,
                icon: 'new',
                bold: true,
                className: '',
              },
              {
                label: 'Art émergent',
                href: '/fr/art/dessins?scene=emergent',
                obfuscate: true,
                icon: 'emerging',
                bold: true,
                className: '',
              },
              {
                label: 'Tous les dessins',
                href: '/fr/art/dessins',
                bold: true,
                className: 'ra-hide-for-large-only ra-hide-for-xlarge',
              },
              { type: 'separator', className: '' },
              {
                label: 'Dessins contemporains',
                href: '/fr/art/dessins',
                obfuscate: true,
                bold: true,
                className: 'ra-show-for-large ra-hide-for-small-only ra-hide-for-medium-only',
              },
            ],
            className: '',
          },
          {
            items: [
              { label: 'Dessins réalistes', href: '/fr/art/dessins/realisme', className: '' },
              { label: 'Dessins abstraits', href: '/fr/art/dessins/abstrait', className: '' },
              {
                label: 'Dessins illustratifs',
                href: '/fr/art/dessins/illustration',
                className: '',
              },
              { label: 'Dessins de nus', href: '/fr/art/dessins/nu', className: '' },
              { label: 'Dessins de portraits', href: '/fr/art/dessins/portrait', className: '' },
              { label: 'Dessins au crayon', href: '/fr/art/dessins/crayon', className: '' },
              { label: 'Dessins au fusain', href: '/fr/art/dessins/fusain', className: '' },
              { label: "Dessins à l'encre", href: '/fr/art/dessins/encre', className: '' },
            ],
            className: '',
          },
          {
            items: [
              {
                type: 'box',
                label: 'Nos plus beaux dessins',
                text: 'Les plus beaux tracés',
                href: '/fr/collection/1695/nos-plus-beaux-dessins',
                image:
                  'https://d1ee3oaj5b5ueh.cloudfront.net/original/file/2023/12/881be6bb-2dbd-4d1c-b0d1-3ae148d20224.jpg',
                className: '',
              },
            ],
            className: '',
          },
        ],
        className: '',
      },
      {
        label: 'Artistes',
        href: '/fr/artistes',
        columns: [
          {
            items: [
              {
                label: 'Tous les artistes',
                href: '/fr/artistes',
                bold: true,
                className: 'ra-hide-for-large-only ra-hide-for-xlarge',
              },
              { label: 'Peintres', href: '/fr/artistes/peintres', bold: true, className: '' },
              { label: 'Graveurs', href: '/fr/artistes/graveurs', bold: true, className: '' },
              {
                label: 'Photographes',
                href: '/fr/artistes/photographes',
                bold: true,
                className: '',
              },
              { label: 'Sculpteurs', href: '/fr/artistes/sculpteurs', bold: true, className: '' },
              {
                label: 'Dessinateurs',
                href: '/fr/artistes/dessinateurs',
                bold: true,
                className: '',
              },
              { label: 'Collagistes', href: '/fr/artistes/collagistes', bold: true, className: '' },
              { type: 'separator', className: '' },
              {
                label: 'Tous les artistes',
                href: '/fr/artistes',
                obfuscate: true,
                bold: true,
                className: 'ra-show-for-large ra-hide-for-small-only ra-hide-for-medium-only',
              },
            ],
            className: '',
          },
          {
            label: 'Etape de carrière',
            items: [
              {
                label: 'Artistes confirmés',
                href: '/fr/artistes?scene=confirme',
                obfuscate: true,
                icon: 'established',
                bold: true,
                className: '',
              },
              {
                label: 'Artistes émergents',
                href: '/fr/artistes?scene=emergent',
                obfuscate: true,
                icon: 'emerging',
                bold: true,
                className: '',
              },
              {
                label: 'Nouveaux et remarquables',
                href: '/fr/artistes?scene=nouveau',
                obfuscate: true,
                icon: 'new',
                bold: true,
                className: '',
              },
              { type: 'separator', className: '' },
              {
                label: 'Artistes à suivre',
                href: '/fr/collection/1415/artiste_a_suivre',
                className: 'ra-show-for-large ra-hide-for-small-only ra-hide-for-medium-only',
              },
              {
                label: "Légendes de l'art",
                href: '/fr/collection/1672/oeuvres-artistes-celebres',
                className: 'ra-show-for-large ra-hide-for-small-only ra-hide-for-medium-only',
              },
            ],
            className: '',
          },
          {
            label: 'Artistes à la une',
            href: '/fr/artistes',
            obfuscate: true,
            items: [
              {
                label: 'Robert Combas',
                href: '/fr/artiste/131219/robert-combas',
                image:
                  'https://d1ee3oaj5b5ueh.cloudfront.net/original/file/2023/12/b78fbd9d-84f4-49dd-97a1-883548f1808d.jpg',
                className: 'ra-show-for-large ra-hide-for-small-only ra-hide-for-medium-only',
              },
              {
                label: 'Richard Orlinski',
                href: '/fr/artiste/131214/richard-orlinski',
                image:
                  'https://d1ee3oaj5b5ueh.cloudfront.net/original/file/2023/12/8a77994f-298c-418c-8ffe-7276bbb1698c.jpg',
                className: 'ra-show-for-large ra-hide-for-small-only ra-hide-for-medium-only',
              },
              {
                label: 'Ben',
                href: '/fr/artiste/131247/ben',
                image:
                  'https://d1ee3oaj5b5ueh.cloudfront.net/original/file/2023/12/5b81c0dc-dfe4-4b16-b080-faf172f69da0.jpg',
                className: 'ra-show-for-large ra-hide-for-small-only ra-hide-for-medium-only',
              },
              {
                label: 'Invader',
                href: '/fr/artiste/33060/invader',
                image:
                  'https://d1ee3oaj5b5ueh.cloudfront.net/original/file/2023/12/344319c3-632a-47c8-a666-45b467e8f9d2.jpg',
                className: 'ra-show-for-large ra-hide-for-small-only ra-hide-for-medium-only',
              },
              {
                label: 'Miss Tic',
                href: '/fr/artiste/131244/miss-tic',
                image:
                  'https://d1ee3oaj5b5ueh.cloudfront.net/original/file/2023/12/cfa6203a-ded4-4e02-971e-12e6a20a1757.jpg',
                className: 'ra-show-for-large ra-hide-for-small-only ra-hide-for-medium-only',
              },
              {
                label: 'JonOne',
                href: '/fr/artiste/131206/jonone',
                image:
                  'https://d1ee3oaj5b5ueh.cloudfront.net/original/file/2023/12/fef33667-15af-4640-9d39-95bf6227ea65.jpg',
                className: 'ra-show-for-large ra-hide-for-small-only ra-hide-for-medium-only',
              },
            ],
            className: '',
          },
          {
            label: '',
            items: [
              {
                label: 'Keith Haring',
                href: '/fr/artiste/131254/keith-haring',
                image:
                  'https://d1ee3oaj5b5ueh.cloudfront.net/original/file/2023/12/41c03ee2-a9cc-476a-94ec-e0f70074e908.jpg',
                className: 'ra-show-for-large ra-hide-for-small-only ra-hide-for-medium-only',
              },
              {
                label: 'Kaws',
                href: '/fr/artiste/43135/kaws-artist',
                image:
                  'https://d1ee3oaj5b5ueh.cloudfront.net/original/file/2023/12/35595446-bd85-49c8-a6dd-14b8de1599eb.jpg',
                className: 'ra-show-for-large ra-hide-for-small-only ra-hide-for-medium-only',
              },
              {
                label: 'C215',
                href: '/fr/artiste/131215/c215',
                image:
                  'https://d1ee3oaj5b5ueh.cloudfront.net/original/file/2023/12/a7fac293-7f85-4478-867f-6e6b5f47a513.jpg',
                className: 'ra-show-for-large ra-hide-for-small-only ra-hide-for-medium-only',
              },
              {
                label: 'Tracey Emin',
                href: '/fr/artiste/24975/tracey-emin/art',
                image:
                  'https://d1ee3oaj5b5ueh.cloudfront.net/original/file/2023/12/371a2aab-c1cd-4715-bda7-b453c5fde243.jpg',
                className: 'ra-show-for-large ra-hide-for-small-only ra-hide-for-medium-only',
              },
              {
                label: 'Frank Stella',
                href: '/fr/artiste/131258/frank-stella',
                image:
                  'https://d1ee3oaj5b5ueh.cloudfront.net/original/file/2023/12/8271f7f5-14af-4001-a015-c7208465fb3d.jpg',
                className: 'ra-show-for-large ra-hide-for-small-only ra-hide-for-medium-only',
              },
              {
                label: 'Yoshitomo Nara',
                href: '/fr/artiste/131255/yoshitomo-nara',
                image:
                  'https://d1ee3oaj5b5ueh.cloudfront.net/original/file/2023/12/17faaf88-b954-4ad5-887d-956b605df40c.jpg',
                className: 'ra-show-for-large ra-hide-for-small-only ra-hide-for-medium-only',
              },
            ],
            className: '',
          },
        ],
        className: '',
      },
      {
        label: 'Inspiration',
        href: '/fr/articles',
        columns: [
          {
            label: 'Edito',
            href: '/fr/articles',
            obfuscate: true,
            items: [
              {
                label: 'Edito',
                href: '/fr/articles',
                className: 'ra-hide-for-large-only ra-hide-for-xlarge',
              },
              { label: "Interviews d'artistes", href: '/fr/articles/artistes', className: '' },
              {
                label: 'Actualités artistiques',
                href: '/fr/articles/actualites-artistiques',
                className: '',
              },
              { label: 'Événements à venir', href: '/fr/evenements', className: '' },
              { type: 'separator', className: '' },
              {
                label: "Catalogue d'art",
                href: 'https://get.riseart.com/fr/catalogues/',
                obfuscate: true,
                external: true,
                className: '',
              },
              { label: 'Guides artistiques', href: '/fr/guides', className: '' },
            ],
            className: '',
          },
          {
            label: 'Collections',
            href: '/fr/collections',
            items: [
              {
                label: "Collections d'art",
                href: '/fr/collections',
                className: 'ra-hide-for-large-only ra-hide-for-xlarge',
              },
              {
                label: 'Sélections de nos Curateurs',
                href: '/fr/collection/1313/selections-de-nos-curateurs',
                className: 'ra-show-for-large ra-hide-for-small-only ra-hide-for-medium-only',
              },
              {
                label: 'Nouveautés de la semaine',
                href: '/fr/collection/1402/nouveautes-de-la-semaine',
                className: 'ra-show-for-large ra-hide-for-small-only ra-hide-for-medium-only',
              },
              {
                label: 'Wishlist du Collectionneur',
                href: '/fr/collection/222/sur-quoi-les-collectionneurs-ont-ils-loeil',
                className: 'ra-show-for-large ra-hide-for-small-only ra-hide-for-medium-only',
              },
              { type: 'separator', className: '' },
              {
                label: 'Artiste en pleine ascension',
                href: '/fr/art?scene=emergent',
                obfuscate: true,
                className: 'ra-show-for-large ra-hide-for-small-only ra-hide-for-medium-only',
              },
              {
                label: "Œuvres d'art grand format",
                href: '/fr/art?dimensions=L-XXL',
                obfuscate: true,
                className: 'ra-show-for-large ra-hide-for-small-only ra-hide-for-medium-only',
              },
              {
                label: 'Œuvres à prix doux',
                href: '/fr/art?dimensions=S-XXL&prix=0-1500',
                obfuscate: true,
                className: 'ra-show-for-large ra-hide-for-small-only ra-hide-for-medium-only',
              },
            ],
            className: '',
          },
          {
            label: 'Services',
            items: [
              {
                label: 'Test de personnalité artistique',
                href: '/fr/quiz/debuter',
                className: 'ra-show-for-large ra-hide-for-small-only ra-hide-for-medium-only',
              },
              {
                label: 'Conseils artistiques',
                href: '/fr/service-art-advisory#reserver-creneau',
                obfuscate: true,
                className: 'ra-show-for-large ra-hide-for-small-only ra-hide-for-medium-only',
              },
              {
                label: 'Commandes sur mesure',
                href: 'https://get.riseart.com/fr/commandes/',
                obfuscate: true,
                external: true,
                className: '',
              },
              { type: 'separator', className: '' },
              {
                label: 'Carte Cadeau',
                href: '/fr/cartes-cadeaux',
                className: 'ra-show-for-large ra-hide-for-small-only ra-hide-for-medium-only',
              },
            ],
            className: '',
          },
          {
            items: [
              {
                type: 'box',
                label: 'Edito',
                text: 'Prenez place à la table de Kate McCrickard',
                href: '/fr/article/2721/interview-kate-mccrickard',
                image:
                  'https://d1ee3oaj5b5ueh.cloudfront.net/original/file/2023/12/b92c5977-e1e4-4844-aeb1-5272551e52fa.jpg',
                className: 'ra-show-for-large ra-hide-for-small-only ra-hide-for-medium-only',
              },
            ],
            className: '',
          },
        ],
        className: '',
      },
      {
        label: "Service d'Art Advisory",
        href: ' /fr/service-art-advisory',
        columns: [
          {
            label: "Service d'Art Advisory",
            items: [
              {
                label: 'Nos Curateurs',
                href: 'https://get.riseart.com/fr/curation/',
                obfuscate: true,
                external: true,
                className: '',
              },
              {
                label: "Service d'Art Advisory",
                href: '/fr/service-art-advisory',
                obfuscate: true,
                className: '',
              },
              { label: "Location d'œuvres d'art", href: '/fr/pour-les-decorateurs', className: '' },
              {
                label: 'Retours gratuits',
                href: 'https://get.riseart.com/fr/retours',
                obfuscate: true,
                external: true,
                className: '',
              },
              { type: 'separator', className: '' },
              {
                label: 'Contacter un conseiller artistique',
                href: '/fr/service-art-advisory#reserver-creneau',
                obfuscate: true,
                className: 'ra-show-for-large ra-hide-for-small-only ra-hide-for-medium-only',
              },
            ],
            className: '',
          },
          {
            label: 'Service pour les Professionnels',
            items: [
              {
                label: 'Rise Art Pro',
                href: 'https://pro.kazoart.com/fr',
                external: true,
                className: '',
              },
              { label: 'Pour les Entreprises', href: '/fr/pour-les-entreprises', className: '' },
              {
                label: "Pour les Décorateurs d'intérieur",
                href: '/fr/pour-les-decorateurs-dinterieur',
                className: '',
              },
              { type: 'separator', className: '' },
              {
                label: 'Contacter notre équipe',
                href: '/fr/service-art-advisory#reserver-creneau',
                obfuscate: true,
                className: 'ra-show-for-large ra-hide-for-small-only ra-hide-for-medium-only',
              },
            ],
            className: '',
          },
          {
            items: [
              {
                type: 'box',
                label: 'Parler à un curateur',
                text: 'Service de conseil gratuit pour les collectionneurs et les entreprises',
                href: '/fr/service-art-advisory#reserver-creneau',
                obfuscate: true,
                image:
                  'https://d1ee3oaj5b5ueh.cloudfront.net/original/file/2023/12/a298714e-ed3f-4232-b737-4bea5e74b56c.jpg',
                className: 'ra-show-for-large ra-hide-for-small-only ra-hide-for-medium-only',
              },
            ],
            className: '',
          },
        ],
        className: '',
      },
    ],
    footerLinks: [
      {
        name: 'POUR LES ARTISTES',
        links: [
          {
            label: 'Soumettez Votre Art',
            href: 'https://get.riseart.com/fr/soumettre/art/',
            obfuscate: true,
            external: true,
          },
          {
            label: 'Espace Vendeur',
            href: 'https://sellers.riseart.com?locale=fr-FR',
            obfuscate: true,
            external: true,
          },
        ],
      },
      {
        name: 'POUR LES ACHETEURS',
        links: [
          {
            label: 'Avis Clients',
            href: 'https://www.reviews.io/company-reviews/store/riseart-com',
            obfuscate: true,
            external: true,
          },
          {
            label: 'La Curation Rise Art',
            href: 'https://get.riseart.com/fr/curation/',
            obfuscate: true,
            external: true,
          },
          {
            label: 'Retours',
            href: 'https://get.riseart.com/fr/retours/',
            obfuscate: true,
            external: true,
          },
          {
            label: 'Encadrez Votre Œuvre',
            href: 'https://get.riseart.com/fr/encadrement/',
            obfuscate: true,
            external: true,
          },
          {
            label: 'FAQ Clients',
            href: 'https://aide.riseart.com/',
            obfuscate: true,
            external: true,
          },
        ],
      },
      {
        name: 'À PROPOS',
        links: [
          { label: 'À propos', href: '/fr/a-propos', external: false },
          { label: 'Contactez-nous', href: '/fr/contactez-nous', external: false },
          {
            label: 'Soho Home x Rise Art',
            href: 'https://get.riseart.com/sohohome/',
            obfuscate: true,
            external: true,
          },
          { label: 'Cartes Cadeaux', href: '/fr/cartes-cadeaux', external: false },
          { label: 'Mentions Légales', href: '/fr/legal', external: false },
        ],
      },
    ],
  });
}
